
    const awsConfig = {
        "stage": "staging",
        "aws_project_region": "eu-west-1",
        "aws_cognito_region": "eu-west-1",
        "aws_user_pools_id": "eu-west-1_oqAdKlBY8",
        "aws_user_pools_web_client_id": "3us54hnd174q4aqq7hs4g3i9f3",
        "aws_cloud_logic_custom": [
            {
                "name": "backend",
                "endpoint": "https://7p6l0dfyag.execute-api.eu-west-1.amazonaws.com/"
            }
        ]
    }
    export default awsConfig
    